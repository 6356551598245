body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.right.carousel-control, .left.carousel-control {
  display: none;
}

.clo-footer-style{
  margin-top: 20px;
  border-color: #5cb85c;
  border-top-style: solid;
  border-top-width: 2px;
  margin-right: 50px;
  margin-left: 50px;
}

.clo-footer-image-size{ 
  max-height: 64px;
  padding-right: 5px;
}

.clo-header-image-style{
  max-height: 60px;
  margin-top: 40px;
}

.clo-statement-style{
  color:#5cb85c;
  text-align: center;
}

.green-title{
  color:darkgreen;
  font-size: larger;
}

html { background-color: #e6f6e6}

.center-check-box{
  text-align: center;
  padding-top: 35px;
}